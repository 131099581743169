import { USER_LOGIN, UPDATE_PROFILE, USER_LOGOUT } from './actionTypes';
import { FORM_SUBMIT_FAIL } from '../errors/actionTypes';
import {emptyCart} from '../store/cart/actions'

import axios from '../axios';

export const login = formData => dispatch => {
  axios.post('/users/login', formData)
    .then(res => {
      const {
        status,
        message,
        id,
        firstname,
        lastname,
        email,
        role,
        profile,
        createdAt,
        updatedAt,
        token
      } = res.data;

      if (status !== true) {
        return dispatch({
          type: FORM_SUBMIT_FAIL,
          payload: {
            status,
            message,
          },
        });
      }

      const payload = {
        id,
        lastname,
        firstname,
        email,
        profile,
        role,
        createdAt,
        updatedAt,
        token
      }

      return dispatch({
        type: USER_LOGIN,
        payload,
      });

    })
    .catch(err => console.log(err));
}

export const updateProfile = (formData, token) => dispatch => {
  const config = {
    headers: {
      'Authorization': token,
    }
  };

  axios.put('/users/update', formData, config)
    .then(res => {
      const {
		    id,
        firstname,
        lastname,
        email,
        profile,
        role,
        createdAt,
        updatedAt,
        token
      } = res.data;

      const payload = {
        id,
        firstname,
        lastname,
        email,
        profile,
        role,
        createdAt,
        updatedAt,
        token
      }

      return dispatch({
        type: UPDATE_PROFILE,
        payload
      });

    })
    .catch(err => console.log(err));

}

export const logout = () => dispatch => {
  localStorage.removeItem('state');

  dispatch(emptyCart());

  return dispatch({
    type: USER_LOGOUT,
    payload: {}
  });
}