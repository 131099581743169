import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';


// const PrivateRoute = ({ component: Component, user, ...rest }) => {
//   return (
//     <Route
//       {...rest}
//       render={props => user.auth ? <Component {...props} /> : <Redirect to="/login" />}
//     />
//   );
// }
const PrivateRoute = ({ component: Component, user, roles, ...rest }) => (
  <Route {...rest} render={props => {
    if (!user.auth) {
        return <Redirect to="/login" />
    }
    // check if route is restricted by role
    if (roles && roles.indexOf(user.role) === -1) {
        // role not authorised so redirect to home page
        return <Redirect to={{ pathname: '/'}} />
    }

    // authorised so return component
    return <Component {...props} />
}} />
)


const mapStateToProps = state => ({
  user: state.user.data,
});

//export default withRouter(connect(mapStateToProps, {})(PrivateRoute));
export default connect(mapStateToProps, {})(PrivateRoute);