import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import PrivateRoute from './scenes/components/PrivateRoute';
import asyncComponent from './scenes/components/hoc/asyncComponent';
import Rezervacija from './scenes/rezervacija/rezervacija.form';

const Home = from2('./scenes/home.form');
const Login = from2('./scenes/login.form');
const Register = from2('./scenes/register.form');
const Profile = from2('./scenes/profile.form');
const Users = from2('./scenes/users.form');
const Basic = from2('./scenes/basic');
const NedodeljenaOpravila = from2('./scenes/admin/nedodeljenaopravila.form');
const Opravila = from2('./scenes/tehnik/opravilatehnika.form');
const OpraviloEdit = from2('./scenes/admin/opraviloedit.form');
const OpraviloDodeli = from2('./scenes/admin/opravilododeli.form');
const MesecnoPorocilo = from2('./scenes/porocilo.form');
const NedokoncanaOpravila = from2('./scenes/admin/nedokoncanaopravila.form');
const OpravilaAdmin = from2('./scenes/admin/opravilaadmin.form');
const IzdelkiAdmin = from2('./scenes/trgovina/izdelki.form');
const IzdelekEdit = from2('./scenes/trgovina/izdelekedit.form');
const IzdelkiPub = from2('./scenes/trgovina/izdelkipub.form');
const Narocila = from2('./scenes/trgovina/narocila.form');
const MojaNarocila = from2('./scenes/trgovina/mojanarocila.form');

function from(path) {
  return asyncComponent(() => {
    return import(`${path}/index.js`);
  });
}

function from2(path) {
  return asyncComponent(() => {
    return import(`${path}`);
  });
}

export default () => (
  <Switch>
    <PrivateRoute exact path="/" component={IzdelkiPub} />
    <Route path="/login" component={Login} />
    <Route path="/register" component={Register} />
    {/* <Route path="/urnik" component={Basic} /> */}
    <PrivateRoute path="/profile" component={Profile} />
    {/* <PrivateRoute path="/users/all" component={Users} roles={['admin']}/>
    <PrivateRoute path="/rezervacija" component={Rezervacija} />
    <PrivateRoute path="/nedodeljeno" component={NedodeljenaOpravila} roles={['admin']} />
    <PrivateRoute path="/opravila" component={Opravila} />
    <PrivateRoute path="/opravilaedit" component={OpraviloEdit} roles={['admin', 'raziskovalec']} />
    <PrivateRoute path="/opravilododeli" component={OpraviloDodeli} />
    <PrivateRoute path="/porocilo" component={MesecnoPorocilo} />
    <PrivateRoute path="/nedokoncano" component={NedokoncanaOpravila} roles={['raziskovalec']}/>
    <PrivateRoute path="/opravilaadmin" component={OpravilaAdmin} roles={['admin']} /> */}

    <PrivateRoute path="/izdelki" component={IzdelkiAdmin} roles={['admin']} />
    <PrivateRoute path="/izdelek" component={IzdelekEdit} roles={['admin']} />
    <PrivateRoute path="/izdelkipub" component={IzdelkiPub} roles={['admin', 'tehnik']} />
    <PrivateRoute path="/narocila" component={Narocila} roles={['admin']} />
    <PrivateRoute path="/mojanarocila" component={MojaNarocila} roles={['admin', 'tehnik']} />

    <Redirect exact path="/users" to="/users/all" />
    <Route render={() => <Redirect to="/" />} />
  </Switch>
);