import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import Drawer from "@material-ui/core/Drawer";
import { compose } from 'redux';
import { connect } from 'react-redux';

import NavigationList from "./navigationList";

const drawerWidth = 240;

// const styles = theme => ({
//   drawerPaper: {
//     position: 'fixed',
//     width: drawerWidth,
//   }
// });

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  hide: {
    display: "none",
  },
  drawerPersistent: {
    width: drawerWidth,
    flexShrink: 0,
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  appBarPersistent: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShiftPersistent: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  menuButtonPersistent: {
    marginLeft: 12,
    marginRight: 20,
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  drawerHeaderPersistent: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  contentPersistent: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShiftPersistent: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
});

const SideNav = (props) => {
  //debugger;
  const { classes, theme, user } = props;

  return (
    <React.Fragment>
      {user.role !== 'tehnik' && 
        <Drawer
            variant="permanent"
            className="sideNav"
            open={false}
            classes={{
            paper: classes.drawer,
            }}
        >
            <div style={{ height: 20 }} />
            <NavigationList />
        </Drawer>
      } 
    </React.Fragment>
    // <Drawer
    //                         container={props.container}
    //                         variant="temporary"
    //                         //anchor={theme.direction === 'rtl' ? 'left' : 'right'}
    //                         open={true}
    //                         //onClose={this.handleDrawerToggle}
    //                         classes={{
    //                             paper: classes.drawerPaper,
    //                         }}
    //                     >
    //                         <NavigationList />
    //                     </Drawer>
  );
};

SideNav.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    user: state.user.data,
  });

export default compose(
    withStyles(styles),
    connect(mapStateToProps, {})
  )(SideNav);
