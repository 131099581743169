import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    minWidth: 0,
  },
  toolbar: theme.mixins.toolbar,
  logged: {
    paddingLeft: 264,
  },
  loggedShopUser: {
    [theme.breakpoints.up('md')]: {
      width: '1280px',
    },

    // width: '1280px',
    margin: '0 auto',
  },
  // notLogged: {
  //   width: '800px',
  //   margin: '0 auto',
  // }
});

class Main extends Component  {
  render() {
    const { classes, children, user } = this.props;
    
    return (
      <main className={[classes.content, 'content', user.auth ? (user.role === 'tehnik' ? classes.loggedShopUser : classes.logged ) : classes.notLogged].join(' ')}>
        <div className={[classes.toolbar, 'content-toolbar'].join(' ')} />
        {children}
      </main>
    );
  }
}

Main.prototypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  user: state.user.data,
});

// export default withRouter(
//   compose(
//     withStyles(styles),
//     connect(mapStateToProps, {})
//   )(Main)
// );
export default 
  compose(
    withStyles(styles),
    connect(mapStateToProps, {})
  )(Main)
;