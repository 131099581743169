import { combineReducers } from 'redux';
import usersReducer from './users/reducer';
import errorsReducer from './errors/reducer';
import cartReducer from './store/cart/reducer';
import totalReducer from './store/total/reducer';
import shelfReducer from './store/shelf/reducer';


export default combineReducers({
  user: usersReducer,
  error: errorsReducer,
  cart: cartReducer,
  total: totalReducer,
  shelf: shelfReducer
});