const DatepickerMessages = {
    invalidDateMessage: 'Napačen format datuma',
    maxDateMessage: 'Izbrali ste prevelik datum',
    minDateMessage: 'Izbrali ste premajhen datum',
    cancelLabel: 'Prekliči',
    clearLabel: 'Počisti',
    todayLabel: 'Danes',
    okLabel: 'OK'
};

export default DatepickerMessages;