import React from 'react';
import { Component } from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import ListIcon from '@material-ui/icons/List';
import CarIcon from '@material-ui/icons/DirectionsCar'
import AssignmentLate from '@material-ui/icons/AssignmentLate'
import Assignment from '@material-ui/icons/Assignment'
import NoteAdd from '@material-ui/icons/NoteAdd';
import SubjectIcon from '@material-ui/icons/Subject';
import {List, ListSubheader, ListItemIcon, ListItem, ListItemText  } from '@material-ui/core';

class NavigationList extends Component{
  
render(){
  const { user } = this.props;

  return (
    <List 
      className="nav-link"
      subheader={<ListSubheader component="div">Menu</ListSubheader>}>
      {/* <ListItem button component={Link} to="/">
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Domov" />
      </ListItem> */}

      {/* { 
        user.role === "admin" ? 
          (
          <ListItem button component={Link} to="/users/all">  
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary="Seznam uporabnikov" />
          </ListItem>) : null
      }

      {
        user.role === "admin" ? 
        (
            <ListItem button component={Link} to="/nedodeljeno">
              <ListItemIcon>
                <AssignmentLate />
              </ListItemIcon>
              <ListItemText primary="Nedodeljena opravila" />
            </ListItem>
        ) : null
      }

      {
        user.role === "admin" ? 
        (
            <ListItem button component={Link} to="/opravilaadmin">
              <ListItemIcon>
                <AssignmentLate />
              </ListItemIcon>
              <ListItemText primary="Opravila vseh uporabnikov" />
            </ListItem>
        ) : null
      }

      {
        user.role === "tehnik" || user.role === "admin" || user.role === "raziskovalec" ? 
        (
            <ListItem button component={Link} to="/opravila">
              <ListItemIcon>
                <Assignment />
              </ListItemIcon>
              <ListItemText primary="Moja opravila" />
            </ListItem>
        ) : null
      }

      {
        user.role === "raziskovalec" || user.role === "admin"  ? 
        (
            <ListItem button component={Link} to="/opravilaedit">
              <ListItemIcon>
                <NoteAdd />
              </ListItemIcon>
              <ListItemText primary="Dodaj opravilo" />
            </ListItem>
        ) : null
      }

      {
        user.role === "raziskovalec" ? 
        (
            <ListItem button component={Link} to="/nedokoncano">
              <ListItemIcon>
                <AssignmentLate />
              </ListItemIcon>
              <ListItemText primary="Nedokončana opravila" />
            </ListItem>
        ) : null
      }

      {
        user.role === "tehnik" || user.role === "admin" || user.role === "raziskovalec" ? 
        (
            <ListItem button component={Link} to="/porocilo">
              <ListItemIcon>
                <SubjectIcon />
              </ListItemIcon>
              <ListItemText primary="Mesečno poročilo" />
            </ListItem>
        ) : null
      }
      
      <ListItem button component={Link} to="/rezervacija">
        <ListItemIcon>
          <CarIcon />
        </ListItemIcon>
        <ListItemText primary="Rezervacija službenih vozil" />
      </ListItem> */}

      {
        user.role === "admin" ? 
        (
            <ListItem button component={Link} to="/izdelki">
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="Urejanje izdelkov" />
            </ListItem>
        ) : null
      }

      {
        user.role === "admin" ? 
        (
            <ListItem button component={Link} to="/izdelkipub">
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="Izdelki public" />
            </ListItem>
        ) : null
      }

      {
        user.role === "admin" ? 
        (
            <ListItem button component={Link} to="/narocila">
              <ListItemIcon>
                <AssignmentLate />
              </ListItemIcon>
              <ListItemText primary="Naročila" />
            </ListItem>
        ) : null
      }

    </List>
  );
  }
}

const mapStateToProps = state => ({
  user: state.user.data,
});

export default connect(mapStateToProps, {}) (NavigationList);